<template>
  <div class="custom-article">
    <div
      v-for="(b, i) in contentBlocks"
      :key="i"
      :class="'size-' + breakpoint"
    >
      <div
        v-if="b.renderView === 'html'"
        v-html="b.html"
        class="sp-b-html"
        @click="hyperlinkEvent"
      />

      <div
        v-else-if="b.renderView === 'image'"
        class="sp-b-img"
      >
        <h4
          v-if="b.props.title.value"
          class="sp-b-img-title"
        >
          {{ b.props.title.value }}
        </h4>
        <div class="position-relative text-center">
          <span
            :class="
              'img-wrap image-' +
              ((b.props.file.options.displaySize &&
                b.props.file.options.displaySize.toLowerCase()) ||
                'original')
            "
          >
            <img :src="b.props.file.value | cdn" />
            <ImageMarker
              v-for="(marker, idx) in b.markers"
              :key="idx"
              :data="marker"
            />
          </span>
        </div>
        <p class="sp-b-img-descr text-secondary text-center font-italic">
          {{ b.props.caption.value }}
        </p>
      </div>

      <div
        v-else-if="b.renderView === 'video'"
        class="sp-b-video"
      >
        <div class="sp-b-video-wrap embed-responsive embed-responsive-16by9">
          <iframe
            class="embed-responsive-item"
            :src="getVideoUrl(b)"
            allowfullscreen
          />
        </div>
        <div
          v-if="b.props.title.value"
          class="title-wrap"
        >
          <h4 class="sp-b-video-title">
            {{ b.props.title.value }}
          </h4>
        </div>
      </div>

      <div
        v-else-if="b.renderView === 'accordion'"
        role="tablist"
        class="sp-b-accordion"
      >
        <div
          class="sp-b-collapse panel panel-default"
          v-for="(collapse, i) in b.root.contentBlocks"
          :key="i"
          :class="{ collapsed: collapsedMap[collapse.key] }"
        >
          <div
            class="sp-b-collapse-toggle panel-heading"
            @click="toggleCollapse(b, collapse)"
          >
            <div
              v-if="collapse.props.image"
              class="image-wrap"
            >
              <img :src="collapse.props.image.value" />
            </div>
            <h3>{{ collapse.props.title.value }}</h3>
            <span class="icon icon-caret" />
          </div>
          <div
            class="sp-collapse"
            role="tabpanel"
          >
            <div class="panel-body">
              <CustomArticle
                :content="collapse.root"
                ref="nestedArticle"
              />
            </div>
          </div>
        </div>
      </div>

      <StepByStep
        v-else-if="b.renderView === 'stepByStep'"
        :b="b"
      />

      <div v-else-if="b.renderView === 'group'">
        <CustomArticle
          :content="b.root"
          ref="nestedArticle"
        />
      </div>

      <div
        v-else-if="b.renderView === 'table'"
        class="sp-table-wrap"
      >
        <h2
          v-if="b.props.title.value"
          class="sp-table-title"
        >
          {{ b.props.title.value }}
        </h2>
        <Table :data="b" />
        <p class="text-secondary text-center font-italic white-space-pre">
          {{ b.props.caption.value }}
        </p>
      </div>

      <div
        v-else-if="b.renderView === 'buttons'"
        class="sp-buttons-block-wrap"
      >
        <div
          class="sp-buttons-block"
          :class="'align-buttons-' + b.props.align.value"
        >
          <div
            class="btn-wrap"
            v-for="(btn, i) in b.buttons"
            :key="i"
          >
            <button
              :class="[
                'btn btn-' + (btn.variant || 'outline-primary'),
                { active: i === activeButton },
              ]"
              @click="btnClick(btn, i)"
            >
              {{ btn.label }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { ButtonAction } from '~/enums/content'
import { ImageMarker } from '~/components'
import Table from '~/components/Table.vue'
import StepByStep from '~/components/StepByStep.vue'
import { ContentBlockCollection as TContentBlockCollection } from './model'

export default {
  name: 'CustomArticle',
  props: {
    content: { type: [String, Object], default: '' },
  },
  components: { ImageMarker, Table, StepByStep },
  inject: ['getContainerBreakpoint'],
  data() {
    return {
      containerWidth: null,
      accordions: {},
      imgWrapStyle: { height: 'auto' },
      stepsView: 'Slider',
      collapsedMap: {},
      breakpoint: 'xs',
      activeButton: -1,
    }
  },
  mounted() {
    this.initInternalContent(this.contentBlocks)
    this.breakpoint = this.getContainerBreakpoint()
  },
  computed: {
    root() {
      let parsed
      if (this.content) {
        if (typeof this.content === 'string') {
          try {
            parsed = JSON.parse(this.content)
          } catch (e) {
            parsed = {}
          }
        } else {
          parsed = this.content || {}
        }
      }

      return new TContentBlockCollection(parsed)
    },
    contentBlocks() {
      return this.root.contentBlocks || []
    },
  },
  methods: {
    btnClick(btn, i) {
      this.activeButton = i
      if (btn.action) {
        switch (btn.action.type) {
          case ButtonAction.URL:
            this.onButtonActionUrl(btn.action, btn.target)
            break
          case ButtonAction.JS:
            this.onButtonActionJs(btn.action)
            break
          default:
            this.$emit('button-action', btn.action)
        }
        this.$emit('button-click', { type: btn.action.type, value: btn.label })
      }

      // Backward compatibility with old content
      if (btn.onClick) {
        this.onButtonActionJs({ value: btn.onClick })
        this.$emit('button-click', { type: ButtonAction.JS, value: btn.label })
      }

      if (btn.href) {
        this.onButtonActionUrl({ value: btn.href })
        this.$emit('button-click', { type: ButtonAction.URL, value: btn.label })
      }
    },
    onButtonActionUrl(action, target = 'NewTab') {
      this.$root.$emit('app::basModal::link', {
        title: '',
        url: action.value,
        urlActionType: target,
      })
    },
    onButtonActionJs(action) {
      window.setTimeout(action.value, 0)
    },
    initInternalContent(blocks) {
      blocks.forEach((b, i) => {
        if (b.renderView === 'accordion') {
          b.root.contentBlocks.forEach((collapse) => {
            this.collapsedMap[collapse.key] = !collapse.props.isOpened.value
          })
          this.$nextTick(() => {
            this.collapsedMap = { ...this.collapsedMap }
          })
        } else if (b.renderView === 'group') {
          this.initInternalContent(b.root.contentBlocks)
        }
      })
    },
    toggleCollapse(accordion, collapse) {
      if (!this.collapsedMap[collapse.key]) {
        this.collapsedMap[collapse.key] = true
      } else {
        accordion.root.contentBlocks.forEach((c) => {
          this.collapsedMap[c.key] = true
        })
        this.collapsedMap[collapse.key] = false
        // this.$nextTick(() => {
        //   collapse.root.contentBlocks
        //     .filter(b => b.renderView === 'stepByStep')
        //     .forEach(b => this.attachSwiper(b.key.toString(16)))
        // })
      }

      this.collapsedMap = { ...this.collapsedMap }
    },
    getVideoUrl(b) {
      const provider = b.props.video.options.provider
      switch (provider) {
        case 'vimeo':
          return 'https://player.vimeo.com/video/' + b.props.video.options.id
        case 'youtube':
          return 'https://www.youtube.com/embed/' + b.props.video.options.id
        default:
          return ''
      }
    },
    // Method for parent, called externally
    adjustToModal() {
      this.initInternalContent(this.contentBlocks)
    },
    getHref(btn) {
      if (btn.href) {
        return `window.open('${btn.href}', '_blank')`
      } else {
        return ''
      }
    },
    trimNonBrakingSpaces(str) {
      if (!str) return ''
      return str.replace('&nbsp;', ' ')
    },
    hyperlinkEvent(event) {
      const tagName = event.target.tagName
      if (tagName === 'A') {
        const href = event.target.getAttribute('href')
        const target = event.target.getAttribute('data-target')

        if (target) {
          event.preventDefault()
          this.onButtonActionUrl({ value: href }, target)
        }
      }
    },
  },
}
</script>

<style lang="scss">
@import '~/assets/scss/mixins.scss';
@include sp-content-module {
  .custom-article {
    // All blocks
    > div {
      margin-bottom: 0.5em;

      .icon {
        display: inline-block;
        width: 1em;
        height: 1em;
        background-position: 50%;
        background-repeat: no-repeat;

        &.icon-caret {
          background-image: url(data:image/svg+xml;charset=utf8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20viewBox%3D%220%200%2032%2024%22%3E%3Cpath%20stroke%3D%22%2300a9e0%22%20stroke-width%3D%225%22%20fill%3D%22none%22%20d%3D%22M3%206%20L16%2018%20L29%206%22%20stroke-linecap%3D%22round%22%2F%3E%3C%2Fsvg%3E);
        }
      }

      &.size-xs {
      }
      &.size-sm {
        .sp-buttons-block .btn-wrap {
          flex-basis: 50%;
        }
      }
      &.size-md {
        .sp-buttons-block .btn-wrap {
          flex-basis: 50%;
        }
      }
      &.size-lg {
        .sp-buttons-block .btn-wrap {
          flex-basis: 50%;
        }
      }
      &.size-xl {
        .sp-buttons-block .btn-wrap {
          flex-basis: 50%;
        }
      }
    }

    .sp-b-img {
      img {
        border-radius: $media-border-radius;
        max-width: 100%;
      }

      .img-wrap {
        display: inline-block;
        position: relative;
      }

      .image-small {
        width: 50%;

        @media screen and (max-width: 767px) {
          width: 100%;
        }
      }

      .image-large {
        width: 100%;
      }

      .image-original {
        max-width: none;
      }
    }

    .sp-b-video {
      .sp-b-video-wrap {
        border-radius: $media-border-radius;
        height: 100%;
      }

      .title-wrap {
        padding: 1em 1.5em;
        padding-top: 2.5em;
        margin-top: -1.5em;
        border: 1px solid $border-color;
        border-bottom-left-radius: $media-border-radius;
        border-bottom-right-radius: $media-border-radius;

        h4 {
          margin: 0;
          font-weight: 500;
        }
      }
    }

    .sp-b-html,
    .sp-b-table {
      a {
        text-decoration: none;
        &:hover,
        &:active {
          text-decoration: underline;
        }

        &.icon-attachment-link {
          &::after {
            content: '';
            display: inline-block;
            height: 14px;
            width: 14px;
            background-size: 100% 100%;
            margin: 0 2px;
            vertical-align: baseline;
          }
        }
      }

      img {
        max-width: 100%;
      }

      pre {
        white-space: pre-wrap;
      }

      blockquote {
        padding-left: 16px;
        border-left: 4px solid #ccc;
      }

      word-break: break-all; // IE fix
      word-break: break-word;
    }

    ul,
    ol {
      margin-top: 20px;
      margin-bottom: 1em;
      padding-left: 1em;
      list-style: none;
      list-style-position: inside;

      > li {
        margin-bottom: 0.5em;
        list-style: none;

        &:last-child {
          margin-bottom: 0.25em;
        }
      }
    }

    ul {
      margin-left: 1em;

      > li {
        list-style: none;

        &::before {
          content: '\2022';
          color: $accent-color;
          font-weight: bold;
          display: inline-block;
          width: 1em;
          margin-left: -1em;
          text-align: center;
        }
      }

      @for $i from 1 through 9 {
        .ql-indent-#{$i}::before {
          content: '\25CB';
        }
      }
    }

    ol {
      margin-left: 35px;

      > li {
        &::before {
          color: #fff;
          display: inline-block;
          font-size: 12px;
          line-height: 16px;
          padding: 4px 0;
          font-weight: 500;
          background-color: $accent-color;
          border-radius: 11px;
          margin-left: -35px;
          margin-right: 10px;
          text-align: center;
          width: 24px;
        }
      }
    }

    @include multi-level-lists();

    .sp-b-collapse {
      padding: 0;
      border: none;
      box-shadow: none;
      border-radius: 0;
      margin-bottom: 0;
      border-bottom: 1px solid $border-color;

      > .panel-heading,
      > .sp-collapse > .panel-body {
        padding-left: 0;
        padding-right: 0;
      }

      > .sp-b-collapse-toggle.panel-heading {
        padding: 15px;
        background-color: #fff;
        border: none;
        display: flex;
        align-items: center;
        cursor: pointer;

        .icon {
          vertical-align: middle;
          margin-left: 0.25em;
          margin-right: 0.5em;
          width: 24px;
          height: 24px;
          border-radius: 11px;
          border: 1px solid $accent-color;
          background-size: 50%;
          transform: rotate(180deg);
          background-position: center;
        }

        .image-wrap {
          height: 100px;
          width: 100px;
          display: flex;
          justify-content: center;
          align-items: center;

          img {
            max-height: 100%;
          }
        }

        h3 {
          flex: 1;
          margin: 5px 0;
        }
      }

      &.collapsed {
        .sp-collapse {
          height: 0;
          overflow: hidden;
        }

        .sp-b-collapse-toggle.panel-heading {
          .icon {
            transform: rotate(0);
          }
        }
      }

      .sp-b-steps {
        .sp-b-steps-caption {
          padding: 0 20px;
        }
      }
    }

    .sp-b-table {
      width: 100%;
      overflow-x: auto;

      table {
        margin: 0 auto;
        margin-bottom: 1em;
        word-break: break-word;
        table-layout: fixed;

        td {
          border: 1px solid $border-color;
          min-height: 2em;
          padding: 0.5em;

          p {
            margin: 0;
          }
        }
      }
    }
    .sp-buttons-block-wrap {
      overflow: hidden;
      padding: 0 5px; // accomodate button's box-shadow (3.6px)
    }
    .sp-buttons-block {
      display: flex;
      flex-wrap: wrap;
      align-items: flex-start;
      margin: 0 -10px;

      .btn-wrap {
        padding: 10px;
        flex: 0 1 100%;
      }

      .btn {
        width: 100%;
        word-break: break-all; // IE fix
        word-break: break-word;
        white-space: normal;
      }

      &.align-buttons {
        &-start {
          justify-content: flex-start;
        }
        &-center {
          justify-content: center;
        }
        &-end {
          justify-content: flex-end;
        }
        &-justify {
          .btn-wrap {
            flex-grow: 1;
          }
        }
      }
    }
  }
}
</style>
