// https://en.wikipedia.org/wiki/Breadth-first_search
export function BFSIterative(problem) {
  const openSet = []
  const closedSet = new Set()
  const meta = new Map()

  const root = problem.getRoot()
  meta.set(root, null)
  openSet.push(root)

  while (openSet.length) {
    const subtreeRoot = openSet.shift()

    if (problem.isGoal(subtreeRoot)) {
      return constructPath(subtreeRoot, meta)
    }

    problem.getSuccessors(subtreeRoot).forEach((child, action) => {
      if (closedSet[child]) {
        return
      }

      if (!openSet.includes(child)) {
        meta.set(child, [subtreeRoot, action])
        openSet.push(child)
      }
    })

    closedSet.add(subtreeRoot)
  }
}
// Helper for BFSIterative
function constructPath(root, meta) {
  const actionList = []
  let state = root
  while (true) {
    const row = meta.get(state)

    if (row && row.length === 2) {
      state = row[0]
      const action = row[1]
      actionList.push(action)
    } else {
      break
    }
  }
  return actionList.reverse()
}

export function _uniqBy (arr, predicate) {
  if (!Array.isArray(arr)) { return [] }

  const cb = typeof predicate === 'function' ? predicate : (o) => o[predicate]

  const pickedObjects = arr
    .filter(item => item)
    .reduce((map, item) => {
      const key = cb(item)

      if (!key) { return map }

      return map.has(key) ? map : map.set(key, item)
    }, new Map())
    .values()

  return [...pickedObjects]
}

export function createGuid () {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
    const r = (Math.random() * 16) | 0
    const v = c === 'x' ? r : (r & 0x3) | 0x8
    return v.toString(16)
  })
}

export function toQueryString(obj, prefix) {
  const str = []
  for (const p in obj) {
    if (obj.hasOwnProperty(p)) {
      const k = prefix ? `${prefix}[${p}]` : p
      const v = obj[p]

      if (
        v !== null &&
        v !== undefined &&
        v !== '' &&
        !(Array.isArray(v) && v.length === 0)
      ) {
        str.push(
          typeof v === 'object'
            ? toQueryString(v, k)
            : `${encodeURIComponent(k)}=${encodeURIComponent(v)}`,
        )
      }
    }
  }
  return str.join('&')
}