var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:['result-wrap', { mounted: _vm.isMounted }]},[(_vm.devices.length > 0)?[_c('h1',{staticClass:"title",domProps:{"innerHTML":_vm._s(_vm.title)}}),_vm._v(" "),_c('p',{staticClass:"description",domProps:{"innerHTML":_vm._s(_vm.model.description)}}),_vm._v(" "),_c('div',{staticClass:"devices-wrap",class:[
        {
          'two-devices':
            _vm.themeName === 'movistargroup' && _vm.devices.length === 2,
        } ]},_vm._l((_vm.devices.slice(0, _vm.numbers)),function(d,index){return _c('div',{key:index,class:[
          'device-card',
          { 'highest-score-device': index === 0 },
          { compared: _vm.comparedDevices.includes(d.id) } ],on:{"click":function($event){return _vm.handleSelect(d, index)}}},[_c('div',{staticClass:"header"},[(_vm.headersLabelsList[index])?_c('span',[_vm._v(_vm._s(_vm.headersLabelsList[index]))]):_vm._e()]),_vm._v(" "),_c('div',{staticClass:"image-wrap"},[_c('img',{attrs:{"src":d.image}})]),_vm._v(" "),_c('div',{staticClass:"name"},[_vm._v("\n          "+_vm._s(d.name)+"\n        ")]),_vm._v(" "),(!['vfes','vfpt'].includes(_vm.clientName) && d.eshopLink)?_c('button',{staticClass:"btn-buy-device"},[_c('a',{staticClass:"buy-device-link",attrs:{"href":_vm.getEShopLink(d.eshopLink),"target":"_blank"}},[(_vm.themeName === 'three' || _vm.themeName === 'vodafone')?[_vm._v("\n              "+_vm._s(_vm.$t('advisor.buy_now'))+"\n            ")]:[_vm._v("\n              "+_vm._s(_vm.$t('advisor.want_it'))+"\n            ")]],2)]):(['vfes','vfpt'].includes(_vm.clientName))?_c('button',{staticClass:"btn-buy-device",attrs:{"data-slug":!d.eshopLink && ((d.manufacturer.toLowerCase()) + "/" + (d.slug))}},[_c('a',{staticClass:"buy-device-link",attrs:{"href":_vm.getEShopLink(d.eshopLink),"target":"_blank"}},[_vm._v("\n            "+_vm._s(_vm.model.buttonText || _vm.$t('advisor.ver_mas'))+"\n          ")])]):_vm._e()])}),0)]:[_c('div',[_c('div',{staticClass:"bell"}),_vm._v(" "),_c('p',{staticClass:"text-no-matching",domProps:{"innerHTML":_vm._s(_vm.$t('advisor.text_no-matching'))}})])]],2)}
var staticRenderFns = []

export { render, staticRenderFns }