var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:[
    'advisor-preview',
    'flow',
    {
      'result-wrapper': _vm.result.state && !_vm.isNoMatch,
      'no-match': _vm.isNoMatch,
      'comparison-wrapper': !!_vm.comparison,
    } ]},[[(_vm.themeName === 'three')?_c('div',{staticClass:"three-title"},[_c('div',[_vm._v("Help me choose perfect device")])]):_vm._e(),_vm._v(" "),_c('div',{staticClass:"steps"},[_c('div',{staticClass:"step-wrap"},_vm._l((_vm.availableSteps),function(item,index){return _c('div',{key:index},[(
              (item.id !== 0 && _vm.themeName === 'movistargroup') ||
                (item.id !== 0 && _vm.themeName === 'movistar') ||
                (item.id !== 0 && _vm.themeName === 'movistarcl') ||
                (item.id !== 0 && _vm.themeName === 'three') ||
                (item.id !== 0 && _vm.themeName === 'vodafone')
            )?_c('custom-step-flow',{key:index,class:{
              'current-step': index + 1 === _vm.availableSteps.length && !_vm.result.state,
              'last-step': index + 1 === _vm.steps.length,
              'first-child': index === 0,
            },attrs:{"model":_vm.availableSteps[index],"values":_vm.selected,"answers":_vm.answers,"journey":_vm.journey,"search-available-options":_vm.searchAvailableOptions},on:{"update:model":function($event){return _vm.$set(_vm.availableSteps, index, $event)},"update:values":function($event){_vm.selected=$event},"setChosenBlock":_vm.changeActiveIndex}}):(item.id !== 0)?_c('custom-step-flow',{key:index,class:{
              'current-step': index + 1 === _vm.availableSteps.length,
              'last-step': index + 1 === _vm.steps.length,
              'first-child': index === 0,
            },attrs:{"journey":_vm.journey,"model":_vm.availableSteps[index],"values":_vm.selected,"answers":_vm.answers,"search-available-options":_vm.searchAvailableOptions},on:{"update:model":function($event){return _vm.$set(_vm.availableSteps, index, $event)},"update:values":function($event){_vm.selected=$event},"setChosenBlock":_vm.changeActiveIndex}}):_vm._o(_c('priority-step-flow',_vm._b({key:_vm.activeIndex,ref:"priorityStepFlow",refInFor:true,class:[
              'current-step',
              {
                'last-step': index + 1 === _vm.steps.length,
              } ],attrs:{"model":_vm.priorityStep,"values":_vm.priorities},on:{"update:values":function($event){_vm.priorities=$event},"click":_vm.changeActiveIndex}},'priority-step-flow',_vm.options,false)),0,index)],1)}),0)]),_vm._v(" "),_c('div',{staticClass:"result-flow-wrap"},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(!_vm.hideResults),expression:"!hideResults"}]},[(_vm.result.state && _vm.contentType !== 'Article')?_c('result-flow',_vm._b({attrs:{"journey":_vm.journey,"model":_vm.result.model,"devices":_vm.result.devices,"compared-devices":_vm.result.compared,"session-id":_vm.sessionId},on:{"update:comparedDevices":function($event){return _vm.$set(_vm.result, "compared", $event)},"update:compared-devices":function($event){return _vm.$set(_vm.result, "compared", $event)}}},'result-flow',_vm.options,false)):_vm._e(),_vm._v(" "),(_vm.result.state && _vm.contentType === 'Article')?_c('result-article-flow',_vm._b({attrs:{"journey":_vm.journey,"model":_vm.result.model,"products":_vm.result.devices,"session-id":_vm.sessionId}},'result-article-flow',_vm.options,false)):_vm._e()],1)]),_vm._v(" "),(_vm.comparison)?_c('div',{class:['comparison-wrap', { mounted: _vm.comparison }]},[_c('comparison-flow',{attrs:{"devices":_vm.result.devices,"numbers":_vm.numbers}})],1):_vm._e()],_vm._v(" "),_c('div',{staticClass:"controls-wrap"},[(_vm.activeIndex > 0 && _vm.themeName === 'movistarcl')?_c('button',{staticClass:"btn btn-control btn-prev",attrs:{"type":"button"},on:{"click":_vm.restart}},[_vm._v("\n      "+_vm._s(_vm.$t('advisor.btn_start-over'))+"\n    ")]):_vm._e(),_vm._v(" "),(!_vm.result.state)?_c('button',{staticClass:"btn btn-control btn-next",attrs:{"type":"button","disabled":!_vm.stepSelected},on:{"click":_vm.nextStep}},[_vm._v("\n      "+_vm._s(_vm.$t('advisor.btn_next'))+"\n    ")]):_vm._e(),_vm._v(" "),(
        _vm.result.state && !_vm.comparison && !_vm.isNoMatch && _vm.contentType !== 'Article'
      )?_c('button',{directives:[{name:"show",rawName:"v-show",value:(!_vm.hideResults),expression:"!hideResults"}],staticClass:"btn btn-control btn-compare",attrs:{"type":"button"},on:{"click":_vm.toggleComparison}},[_vm._v("\n      "+_vm._s(_vm.$t('advisor.btn_compare'))+"\n    ")]):_vm._e()])],2)}
var staticRenderFns = []

export { render, staticRenderFns }